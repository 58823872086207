<template>
    <div>
        <k-form-build ref="kfb" :value="jsonData" :config="config" />
        <template v-if="approve.isAttach">
            <attach-upload v-model="attachment"></attach-upload>
        </template>
        <div>
            <examine ref="exmines" @focus="examines" :formData="Data.formData" :flowId="Data.flowId">
            </examine>
        </div>
        <div class="footer">
            <a-button @click="$emit('cancel')">取消</a-button>
            <a-button type="primary" @click="validate()">确认提交</a-button>
        </div>
    </div>
</template>

<script>
import utils from '@/common/utils';
import request from '@/config/request';
import { formUploadConfig } from '@/config/request';
import AttachUpload from '@/components/common/attach-upload.vue';
import formUtils from '@/common/formUtils';
import examine from './examine';
export default {
    components: { AttachUpload, examine },
    data() {
        return {
            config: formUploadConfig,
            attachment: [],
            readList: [],
            hideList: [],
            Data: {}
        };
    },
    mounted() {
        this.setRule();
    },
    computed: {
        jsonData() {
            return formUtils.checkDisable(this.data.formJson, this.readList, this.hideList);
        }
    },
    methods: {
        examines() {
            this.$refs.kfb.getData().then(ret => {
                this.Data = {
                    formData: JSON.stringify(formUtils.getData(ret, this.readList, this.hideList)),
                    approveId: this.data.approveId,
                    flowId: this.data.flowId,
                    attachment: this.attachment,
                    proof: this.data.proof
                };
            });
        },
        // 提交
        validate() {
            this.$refs.kfb.getData().then(ret => {
                const jsonData = {
                    formData: JSON.stringify(formUtils.getData(ret, this.readList, this.hideList)),
                    approveId: this.data.approveId,
                    flowId: this.data.flowId,
                    attachment: this.attachment,
                    proof: this.data.proof
                };
                // console.log(formUtils.getData(ret, this.readList, this.hideList));
                // return;
                // console.log(this.data);
                if (!this.$refs.exmines.value && this.data.isStaff) {
                    utils.error("请选择审批员");
                    return;
                }
                // console.log(this.$refs.exmines.value);
                jsonData.staffId = this.$refs.exmines.value;
                utils.showSpin();
                request.post('/platform/apply/doPost', jsonData).then(ret => {
                    this.$emit('success');
                    utils.success('提交成功');
                });
            });
        },
        // 设置表单权限
        setRule() {
            const { readList, hideList } = formUtils.getFieldsRuleList(this.data.formRule);
            if (hideList.length > 0) {
                this.$refs.kfb.hide(hideList);
            }
            if (readList) {
                this.$refs.kfb.disable(readList);
            }
            this.readList = readList;
            this.hideList = hideList;
        },
    },
    props: {
        approve: {
            type: Object,
            default: () => {
                return null;
            }
        },
        data: {
            type: Object,
            default: {}
        }
    }
};
</script>

<style lang="less">
.footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    button {
        margin: 0 10px;
    }
}
</style>