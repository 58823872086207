<template>
    <a-modal v-model="visible" :title="title" :width="modalWidth" :footer="null" centered destroyOnClose>
        <!-- 选择流程 -->
        <template v-if="step == 1">
            <a-layout>
                <div class="content-header">
                    <a-affix :offset-top="0" style="width: 100%;">
                        <a-row type="flex" :gutter="15">
                            <a-col span="8">
                                <a-input v-model="param.keyword" allowClear search @pressEnter="doRefresh"
                                         placeholder="搜索工作流程"
                                         @change="() => { if (param.keyword == '') { doRefresh(); } }"></a-input>
                            </a-col>
                            <a-col span="8">
                                <a-select style="width:100%" placeholder="分类筛选" v-model="param.categoryId" allowClear
                                          @change="doRefresh">
                                    <a-select-option :value="item.categoryId" v-for=" item  in  category "
                                                     :key="item.categoryId">{{
                                                         item.name }}</a-select-option>
                                </a-select>
                            </a-col>
                        </a-row>
                    </a-affix>
                </div>
            </a-layout>
            <a-layout>
                <a-layout-content>
                    <a-table :defaultExpandAllRows="true" :scroll="{ x: '100%' }" :pagination="false" :indentSize="25"
                             :style="{ background: '#FFF' }" size="small" row-key="approveId" border :columns="keys"
                             ref="list" :loading="loading" :data-source="data.records">
                        <template slot="description" slot-scope="row">
                            <a-popover title="流程说明" placement="topLeft">
                                <div style="max-width: 400px; white-space: pre-wrap;" slot="content">
                                    {{ row.description }}
                                </div>
                                {{ row.description }}
                            </a-popover>
                        </template>
                        <template slot-scope="row" slot="action">
                            <a-button type="link" @click="selectApprove(row)">创建工作</a-button>
                        </template>
                    </a-table>
                </a-layout-content>
            </a-layout>
            <a-row>
                <a-col span="12" offset="6" style="text-align: center; padding: 20px 0;">
                    <a-pagination :defaultPageSize="param.limit" :total="data.total" @change="getNext"
                                  @showSizeChange="setLimit" :current="data.current" />
                </a-col>
            </a-row>
        </template>
        <!-- 创建表单 -->
        <template v-if="step == 2">
            <create-form :approve="currentForm" :data="createData" @cancel="() => { step = 1; title = '选择工作流'; }"
                         @success="() => { visible = false; $emit('success'); }"></create-form>
        </template>
    </a-modal>
</template>

<script>
import utils from '@/common/utils';
import apiUtil from '@/common/apiUtil';
import request from '@/config/request';
import createForm from './create-form';
export default {
    components: { createForm },
    data() {
        return {
            visible: false,
            title: '选择工作流',
            step: 1,
            loading: false,
            data: [],
            param: {
                page: 1,
                limit: 20,
                categoryId: '',
                keyword: ''
            },
            keys: [
                { title: '流程', dataIndex: 'name', align: 'left', width: 200, ellipsis: true },
                { title: '说明', scopedSlots: { customRender: 'description' }, align: 'left', ellipsis: true, tooltip: true },
                { title: '操作', scopedSlots: { customRender: 'action' }, align: 'center', width: 100 }
            ],
            currentForm: null,
            createData: null,
            modalWidth: 800,
            formJson: { config: { width: '' } },
        };
    },
    watch: {
        title(v) {
            if (v == '选择工作流') {
                this.modalWidth = 800;
            } else {
                this.modalWidth = this.formJson.config.width > 800 ? this.formJson.config.width : 800;
            }
        }
    },
    methods: {
        ...apiUtil,
        doCreate() {

        },
        // 选择流程
        selectApprove(row) {

            utils.showSpin();
            request.get('/platform/flow/getProcess', { approveId: row.approveId }).then(ret => {
                console.log(ret.data);
                this.createData = ret.data;
                this.formJson = ret.data.formJson;
                this.currentForm = row;
                this.step = 2;
                this.title = '创建工作-' + row.name;
            });
            // this.currentForm = row;
            // this.step = 2;
            // this.title = '创建工作';
        },
        show() {
            this.step = 1;
            this.title = '选择工作流';
            this.visible = true;
            this.doRefresh();
        },
        getCategory() {
            request.get("/platform/approve/category", { organId: this.param.organId }).then(ret => {
                this.category = ret.data;
            });
        },
        //获取数据
        doRefresh() {
            this.param.page = 1;
            this.getList();
        },
        // 获取数据
        getList() {
            this.loading = true;
            this.param.categoryId = !utils.isEmpty(this.param.categoryId) ? this.param.categoryId : '';
            this.http
                .get('/platform/approve/query', this.param)
                .then(ret => {
                    this.data = ret.data;
                    this.loading = false;
                })
                .catch(err => {
                    this.loading = false;
                });
        },
        //设置每页的显示条数
        setLimit(current, limit) {
            this.param.page = 1;
            this.param.limit = limit;
            this.getList();
        },
        //页码切换
        getNext(page) {
            this.param.page = page;
            this.getList();
        }
    },
    props: {
        category: {
            type: Array,
            default: () => {
                return [];
            }
        }
    }
};
</script>

<style scoped></style>
